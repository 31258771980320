<template>
    <div>
        <el-tabs style="width: 100% ;display: block !important; margin-left: 20px;" v-model="activeName"
            @tab-click="handleClick">
            <el-tab-pane label="评 论 语 设 置" name="评论语"></el-tab-pane>
            <el-tab-pane label="任 务 列 表" name="全部"></el-tab-pane>
        </el-tabs>
        <div class="infoBox" style="padding-top: 5px; padding-bottom: 20px">
            <!-- 评论语专区 -->
            <div v-if="activeName == '评论语'" v-loading="Loading">
                <el-input v-model="commentValue" autocomplete="off" placeholder="请输入你要发表评论的评论语"
                    style="width: 60%;"></el-input>
                <el-button class="ml20" type="primary" size="small" @click="addComment('add')">+ 添加</el-button>
                <div v-if="commentList.length > 0">
                    <div v-for="item in commentList" :key="item.id" style="display: flex; align-items: center;">
                        <div class="commentStyle">
                            <div v-if="item.id != edit_id">{{ item.text }}</div>
                            <el-input v-else v-model="editCommentValue" autocomplete="off"></el-input>
                        </div>
                        <div class="flexA">
                            <el-button v-if="item.id == edit_id" type="primary" size="small" class="mr10" @click="addComment('edit')">完成</el-button>
                            <el-button icon="el-icon-edit" circle class="mr10" type="primary"
                                @click="editInfo('comment',item)"></el-button>
                            <el-button icon="el-icon-delete" circle type="danger"
                                @click="deleteInfo('comment', item.id)"></el-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 任务列表专区 -->
            <div v-if="activeName == '全部'">
                <div class="mb20 flexA">
                    <el-button type="primary" size="small" @click="addTask('add')">+ 创建任务</el-button>
                    <div class="ml20">
                        <i class="el-icon-refresh-right" style="cursor: pointer; color: #1e7ce1;" @click="refresh"></i>
                        已开启浏览器 &nbsp;{{ Chrome.num }}&nbsp; 个，运行&nbsp;{{ Chrome.run_num }}&nbsp;个，请遵循开启浏览器个数进行开启相应个数任务
                    </div>
                </div>
                <div class="mb5">
                    <el-button type="primary" size="small" @click="downEXE">下载获客软件</el-button>
                    <span class="ml10" style="color: orangered;">请先下载获客软件，1`在获客软件上打开浏览器并进行登录，2`进入当前页面选择你要运行的任务开启(开启多少个浏览器则只能运行相同个数的任务)</span>
                </div>
                <div class="table-data">
                    <el-table :data="task_data" border ref="multipleTable" tooltip-effect="dark" v-loading="loadShow">
                        <el-table-column prop="index" label="序号" width="70"></el-table-column>
                        <el-table-column prop="task_name" label="任务名称" width="155"></el-table-column>
                        <el-table-column prop="run_time" label="运行时长(分钟)" width="120"></el-table-column>
                        <el-table-column prop="search_key" label="匹配关键词"></el-table-column>
                        <el-table-column prop="comment" label="评论语">
                            <template slot-scope="{ row }">
                                <div v-for="(item, index) in JSON.parse(row.comment)">{{index+1}}、{{  item }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="is_active" label="是否保持活跃" width="120">
                            <template slot-scope="{ row }">
                                <div>{{ row.is_active == 2?'是':'否' }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="100">
                            <template slot-scope="{ row }">
                                <el-tag :type="row.status == 1?'':row.status == 2?'warning':row.status == 3?'success':'danger'">{{ row.status == 1?'待运行':row.status == 2?'运行中':row.status == 3?'任务完成':'执行失败' }}</el-tag>
                                <span v-if="row.err_msg != ''" style="color: grey;font-size: 11px;">原因：{{ row.err_msg }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="create_time" label="创建时间"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="{ row }">
                                <el-button icon="el-icon-edit" circle class="mr4" type="primary"
                                    @click="editInfo('task', row)" :disabled="row.status==2"></el-button>
                                <el-button icon="el-icon-delete" circle type="danger" class="mr4"
                                    @click="deleteInfo('task', row.task_id)" :disabled="row.status==2"></el-button>
                                <el-button v-if="(row.status==1||row.status==3||row.status==4) && Chrome.num != 0 && Chrome.num>Chrome.run_num" :icon="row.status==2?'el-icon-video-pause':'el-icon-video-play'" circle 
                                    :type="row.status==1||row.status==3||row.status==4?'success':'info'" @click="startTask(row.task_id)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pageBox">
                    <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                        @current-change="handleCurrentChange" :current-page="pageNum"
                        :page-sizes="[10]" :page-size="10" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>

        <!-- 新增任务弹窗层 -->
        <el-dialog title="新增任务" :visible.sync="showTask" width="800px" :close-on-click-modal="false">
            <el-form :model="taskInfo">
                <el-form-item label="任务名称：" label-width="140px" class="mb20" prop="task_name">
                    <el-input v-model="taskInfo.task_name" autocomplete="off" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="是否保持账号活跃：" label-width="140px" class="mb20">
                    <el-radio-group v-model="taskInfo.is_active" disabled>
                        <el-radio :label="2">保持活跃</el-radio>
                        <el-radio :label="1">关闭活跃</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="运行时长：" label-width="140px" class="mb20" prop="run_time">
                    <el-input v-model="taskInfo.run_time" autocomplete="off" type="number" style="width: 80%;">
                        <template slot="append">分钟</template>
                    </el-input>
                    <div style="color: orangered;">由于D音机制，建议每次运行不要超过30分钟，可少量多次</div>
                </el-form-item>
                <el-form-item label="匹配关键词：" label-width="140px" class="mb10">
                    <el-input v-model="taskInfo.search_key" autocomplete="off" style="width: 80%;" placeholder="#按摩#精油推拿"></el-input>
                    <div style="color: orangered;">最多可以输入3个关键词，请按照 &nbsp;&nbsp; #按摩#精油推拿 &nbsp;&nbsp; 这样的方式来进行分割</div>
                </el-form-item>
                <el-form-item label="评论语：" label-width="140px" class="mb20">
                    <div v-if="commentList.length > 0" style="height: 200px; overflow: auto;">
                        <el-checkbox-group v-model="taskInfo.comment">
                            <div v-for="item in commentList" :key="item.id">
                                <el-checkbox :label="item.text" name="type"></el-checkbox>
                            </div>
                        </el-checkbox-group>
                    </div>
                    <el-tag v-else type="warning">暂无评论语，请先去添加评论语</el-tag>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showTask = false">取 消</el-button>
                <el-button type="primary" @click="addTask('addSure')" class="ml10">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    AddCommentInfoApi,
    DelCommentInfoApi,
    EditCommentInfoApi,
    GetCommentListApi,
    AddTaskInfoApi,
    DelTaskInfoApi,
    EditTaskInfoApi,
    GetTaskListApi,
    GetChromeOnLine,
    StartTaskInfoApi,
} from "@/common/api";
import { Loading } from "element-ui";
export default {
    data() {
        return {
            loadShow: false,
            activeName: '评论语',
            Loading: false,
            commentValue: '',    //评论语
            editCommentValue: '',    //评论语
            commentList: [],   //评论语列表
            task_data: [],   //任务列表
            showTask: false,
            taskInfo: {
                task_id: '',
                task_name: '',
                is_active: 2,
                run_time: 30,
                search_key: '',
                comment: [],
            },
            pageNum: 1, // 当前页数
            total: 0, // 总页数
            edit_id: '',  //编辑评论语的id
            Chrome: '0',  //浏览器个数
        };
    },
    async created() {
        this.getTableData(1,this.activeName);

    },
    methods: {
        // 切换导航栏
        handleClick() {
            this.getTableData(1,this.activeName)
        },
        refresh(){
            this.getTableData(1,this.activeName)
        },
        async getChormeNum(){
            let res = await GetChromeOnLine()
            if(!res) return
            this.Chrome = res.data
        },
        // 获取数据信息
        async getTableData(page,type) {
            this.Loading = true
            if (page) this.pageNum = page;
            let res
            if (type == '评论语') {
                res = await GetCommentListApi({
                    page: 1
                })
                this.Loading = false
                if (!res) return
                this.commentList = res.data
            } else if (type == '全部') {
                this.getChormeNum()
                res = await GetTaskListApi({
                    page:1
                })
                this.Loading = false
                if (!res) return
                res.data.forEach((element, index) => {
                    element.index = index + 1 + (this.pageNum - 1) * 10;
                });
                this.task_data = res.data
                this.total = res.total;
            }
        },
        // 添加评论语
        async addComment(type) {
            let res
            if(type == 'add'){
                res = await AddCommentInfoApi({
                    text: this.commentValue
                })
            }else{
                this.Loading = true
                res = await EditCommentInfoApi({
                    id: this.edit_id,
                    text: this.editCommentValue
                })
            }
            if (!res) return
            this.edit_id = ''
            this.commentValue = ''
            this.editCommentValue = ''
            this.getTableData(1,this.activeName)
        },
        // 添加任务
        async addTask(type) {
            let time = JSON.parse(localStorage.getItem('due_time'))
            if(time.due_time == '' && !time.isLife){
                this.$message.warning('还未充值，请先充值')
                return
            }
            if(localStorage.getItem('isOverTime') == 'true'){
                this.$message.warning('已过期，请续费后继续使用')
                return
            }
            if(type == 'add'){
                this.taskInfo = {
                    task_id: '',
                    task_name: '',
                    is_active: 2,
                    run_time: 30,
                    search_key: '',
                    comment: [],
                },
                this.showTask = true
            }else{
                let res
                if(this.taskInfo.search_key.trim().split('#').length > 4){
                    this.$message.warning('关键词要在三个以内');
                    return
                }
                if(!this.taskInfo.task_id){
                    res = await AddTaskInfoApi({
                        task_name: this.taskInfo.task_name,
                        is_active: this.taskInfo.is_active,
                        run_time: this.taskInfo.run_time,
                        search_key: this.taskInfo.search_key,
                        comment: JSON.stringify(this.taskInfo.comment)
                    })
                }else{
                    res = await EditTaskInfoApi({
                        task_id: this.taskInfo.task_id,
                        task_name: this.taskInfo.task_name,
                        is_active: this.taskInfo.is_active,
                        run_time: this.taskInfo.run_time,
                        search_key: this.taskInfo.search_key,
                        comment: JSON.stringify(this.taskInfo.comment)
                    })
                }
                if(!res) return
                this.Loading = true
                this.getTableData(1, this.activeName)
                this.showTask = false
            }
            
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData(1, this.activeName);
        },
        // 编辑
        editInfo(type, row) {
            if(type == 'task'){
                this.taskInfo = {
                    task_id: row.task_id,
                    task_name: row.task_name,
                    is_active: row.is_active,
                    run_time: row.run_time,
                    search_key: row.search_key,
                    comment: [],
                }
                let currentComment = JSON.parse(row.comment)
                this.commentList.forEach((i, _index)=>{
                    if(currentComment.indexOf(i.text) != -1){
                        this.taskInfo.comment.push(i.text)
                    }
                })
                this.showTask = true
            }else{
                this.edit_id = row.id
                this.editCommentValue = row.text
            }
        },
        deleteInfo(type, item){
            let res
            this.$confirm('是否确定要【删除】?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( async() => {
                if(type == 'comment'){
                    res = await DelCommentInfoApi({
                        id: item
                    })
                }else{
                    res = await DelTaskInfoApi({
                        task_id: item
                    })
                }
                if(!res) return
                this.getTableData(1, this.activeName)
            })
        },
        startTask(task_id){
            this.$confirm('确定要【开启任务】?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( async() => {
                let res = await StartTaskInfoApi({
                    task_id
                })
                if(!res) return
                this.getTableData(1, this.activeName)
            })
        },
        downEXE(){
            if(JSON.parse(localStorage.getItem('due_time')).due_time == '' && !JSON.parse(localStorage.getItem('due_time')).isLife){
                this.$message.warning('还未充值，请先充值')
                return
            }
            if(localStorage.getItem('isOverTime') == 'true'){
                this.$message.warning('已过期，请续费后继续使用')
                return
            }
            window.location.href='https://hlg-public-file.oss-cn-hangzhou.aliyuncs.com/rpa/anzhuangbao/%E6%9C%BA%E5%99%A8%E4%BA%BA%E5%AE%89%E8%A3%85%E5%8C%85.exe'
        }
    }
};
</script>

<style lang="less" scoped>
.commentStyle {
    height: 60px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;
    margin: 20px 50px 10px 20px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 86%;
}
</style>